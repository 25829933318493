<template>
  <div class="box">
    <div class="loginBox">
      <div class="message">
        健康证后台管理系统登录
      </div>
      <div id="darkbannerwrap"></div>
      <input type="text" v-model="user" placeholder="用户名">
      <input type="text" v-model="password" placeholder="密码">
      <button class="btn" @click="submitForm()">确认</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
    // screenfull,
  },
  data() {
    return {
      user: '',
      password: '',
    }
  },
  mounted() {
  },
  methods: {
    submitForm() {
      if (this.user == 'administer' && this.password == 'hbldqy@0147~!AA') {
        localStorage.setItem('user', 'administer')
        this.$notify({
          title: '成功',
          message: `登陆成功`,
          type: 'success'
        });
        this.$router.push({
          path: '/home'
        })
      } else {
        this.$notify({
          title: '登陆失败',
          message: `账号或者密码错误`,
          type: 'error'
        });
      }
    },

  }
}
</script>
<style lang="scss" scoped>
body {
    // display: block;
    margin: 0;
    padding: 0;
}
input:focus-visible {
  outline:  1px solid #fff;
}
input {
  // border: none;
  border: 1px solid #DCDEE0;
  vertical-align: middle;
  border-radius: 3px;
  height: 50px;
  padding: 0px 16px;
  font-size: 12px;
  color: #555555;
  outline: none;
  width: 100%;
  box-sizing: border-box;
  margin: 5px 0 10px;
}
.box {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(../assets/bg.png) no-repeat center;
  background-size: cover;
  overflow: hidden;
  font-size: 12px;
  // font-size: 12px;
  // padding-top: 100px;
}
.loginBox {
  display: flex;
  flex-flow: column;
  padding: 40px;
  background-color: #fff;
  min-height: 300px;
  width: 340px;
  margin-bottom: 50px;
  border-radius: 4px;
  .message {
    margin: 10px 0 0 -58px;
    padding: 18px 10px 18px 60px;
    background: #189F92;
    position: relative;
    color: #fff;
    font-size: 16px;
  }
  #darkbannerwrap {
    background: url(../assets/aiwrap.png);
    width: 18px;
    height: 10px;
    margin: 0 0 20px -58px;
    position: relative;
}
}

.btn {
  display: inline-block;
    vertical-align: middle;
    padding: 12px 24px;
    margin: 10px 0;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    cursor: pointer;
    color: #ffffff;
    background-color: #189F92;
    border-radius: 3px;
    border: none;
    -webkit-appearance: none;
    outline: none;
    width: 100%;
}

</style>